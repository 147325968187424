
    /deep/.el-table--border::after, .el-table--group::after, .el-table::before{
        display: none;
    }

    .nav{
        display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 54px;
            .el-breadcrumb {
                line-height: 40px;
            }
    }
    .tableClass{
        /deep/th{
            padding: 0!important;
            height: 45px;
            line-height: 45px;
        }
        /deep/td{
            padding: 0!important;
            height: 45px;
            line-height: 45px;
        }
    }
    /deep/.el-pagination{
        display: flex;
        justify-content: center;
        margin: 30px 0;
    }
    .BoothAdvertising{
        padding: 30px;
        height: calc(100vh - 320px);
        .AdvertisingtHeader{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .el-breadcrumb {
                line-height: 40px;
            }
        }
    }
